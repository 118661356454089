import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { Container } from "react-bootstrap"

import Layout from "./layout"
import SEO from "./seo"
import Hero from "./sections/Hero"
import ArticleAdditionalDoc from "./ArticleAdditionalDoc"

import withI18next from "./withI18next"

const ArticleTemplate = ({ data, pageContext, t }) => {
  const article = data.allMarkdownRemark.edges[0].node

  let AdditionalDoc
  if (article.frontmatter.additional_docs !== null) {
    AdditionalDoc = (
      <ArticleAdditionalDoc
        imgFluid={
          article.frontmatter.additional_docs.img_to_blur.childImageSharp.fluid
        }
        filename={article.frontmatter.additional_docs.filename}
        subject={article.frontmatter.additional_docs.subject}
        text={article.frontmatter.additional_docs.text}
      />
    )
  } else {
    AdditionalDoc = ""
  }

  return (
    <>
      <SEO
        title={article.frontmatter.title}
        lang={pageContext.locale}
        description={t("meta_description")}
      />

      <Layout originalPath={pageContext.originalPath}>
        <Hero small />
        <section className="section">
          <Container>
            <Img
              fluid={article.frontmatter.image.childImageSharp.fluid}
              className="mb-3"
              style={{ maxHeight: 500 }}
            />
            <h1>{article.frontmatter.title}</h1>
            <span>
              {t("articles_author_date", {
                author: article.frontmatter.author,
                date: new Date(article.frontmatter.date),
              })}
            </span>
            <hr />
            <div dangerouslySetInnerHTML={{ __html: article.html }} />
          </Container>
        </section>

        {AdditionalDoc}

      </Layout>
    </>
  )
}

export default withI18next({ ns: "common" })(ArticleTemplate)

export const query = graphql`
  query ArticleQuery($id: String!) {
    allMarkdownRemark(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            author
            date
            description
            image {
              childImageSharp {
                fluid(maxHeight: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            additional_docs {
              img_to_blur {
                childImageSharp {
                  fluid(maxWidth: 270) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              subject
              text
              filename
            }
          }
        }
      }
    }
  }
`
