import React, { useState } from "react"
import Img from "gatsby-image"
import axios from "axios"
import validator from "email-validator"
import { useTranslation } from 'react-i18next';

import {
  Container,
  Row,
  Col,
  Card,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap"
import { IoIosMail } from "react-icons/io"

const ArticleAdditionalDoc = (props) => {
  const { t } = useTranslation("articleadditionaldoc");

  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleChange = event => {
    setEmail(event.target.value);
    setIsValid(false);
    setIsInvalid(false);
  }

  const handleSubmit = async event => {
    event.preventDefault()
    event.stopPropagation()
    if (validator.validate(email)) {
      setDisabled(true);
      try {
        const response = await axios.post(
          "https://xe1ztfv3f7.execute-api.eu-central-1.amazonaws.com/default/emailAdditionalDocuments",
          {
            filename: props.filename,
            to: email,
            subject: props.subject,
            text: props.text,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Api-Key': 'X5GkoHUSkI7MZE217BIip10lJbMHV7Q25ZFw91N0'
            },
          }
        );
        if (response.status === 200) {
          setDisabled(false);
          setIsValid(true);
        } else {
          setDisabled(false);
          setIsInvalid(true);
        }
      } catch (error) {
        setDisabled(false);
        setIsInvalid(true);
      }
    } else {
      setIsInvalid(true);
    }
  }

  return (
    <section className="section pt-0">
      <Container>
        {/*
        <h3>
          <div className="icon icon-lg icon-shape icon-shape-default shadow rounded-circle mb-5">
            <IoIosAttach />
          </div>
          <span className="ml-3">{t("title")}</span>
        </h3>
        */}
        <Row>
          <Col md={6} className="mb-5">
            <div className="rounded shadow-lg transform-perspective-left">
              <Img fluid={props.imgFluid} style={{ maxHeight: 270 }} />
            </div>
          </Col>
          <Col md={6}>
            <Card className="bg-secondary shadow border-0">
              <div className="card-header bg-white pb-3">
                <div className="text-muted text-center">
                  { t("form_title") }
                </div>
              </div>
              <Card.Body className="px-lg-5 pt-lg-5 pb-lg-2">
                <Form onSubmit={e => handleSubmit(e)}>
                  <Form.Group className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroup.Prepend>
                        <span className="input-group-text">
                          <IoIosMail />
                        </span>
                      </InputGroup.Prepend>
                      <Form.Control
                        placeholder="Email"
                        type="email"
                        isInvalid={isInvalid}
                        isValid={isValid}
                        disabled={disabled}
                        value={email}
                        onChange={e => handleChange(e)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <div className="text-center">
                    <Button
                      type="submit"
                      variant="primary"
                      className="my-4"
                      disabled={disabled}
                    >
                      { t("send") }
                    </Button>
                  </div>
                </Form>
                <p className="small">{ t("email_disclaimer") }</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ArticleAdditionalDoc;
